import React, { useEffect } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import axios from '../../services/axios/axios';
//import axios from 'axios';
import { AzureAdB2C } from '../../../RAFAzure/AuthModule';
import { RAFHeaderNames } from '../../../constants/Common/Constants';
import { getPureSubDomainOrHint, isNotNullAndUndefined } from '../../helpers/utils';
import { hideProgress, showProgress } from '../../helpers/AppHelper';
import { getAWSToken } from '../../../services/getAWSToken';
import { getCurrentUser } from 'aws-amplify/auth';

interface RequestInterceptorProps {
    children: JSX.Element,
}


// /* eslint-disable no-param-reassign */
// axios.interceptors.request.use(async (config) => {
//     if (!account) {
//         throw Error('No active account! Verify a user has been signed in.');
//     }
//     let progressDiv = showProgress('body');
//     const response = await instance.acquireTokenSilent({
//         scopes: [AzureAdB2C.AcquireTokenScope],
//         account: account
//     });
//     hideProgress(progressDiv);
//     if (isNotNullAndUndefined(response) && typeof response === 'object') {
//         instance['accessToken'] = response.accessToken;


//         const bearer = `Bearer ${response.accessToken}`;
//         config.headers.Authorization = bearer;
//         config.headers[RAFHeaderNames.BusinessUnitUID] = instance['currentBusinessUnitId'];
//         config.headers[RAFHeaderNames.Domain] = getPureSubDomainOrHint();
//     }
//     return config;
// }, (error) => {
//     return Promise.reject(error);
// });

// // Add a response interceptor
// axios.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         return Promise.reject(error);
//     }
// );
// /* eslint-enable no-param-reassign */


let requestInterceptorId: number | null = null;
let responseInterceptorId: number | null = null;

const RequestInterceptor: React.FC<RequestInterceptorProps> = ({ children }: RequestInterceptorProps) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);

    // Add request interceptor
    /* eslint-disable no-param-reassign */
    if (requestInterceptorId === null) {
        requestInterceptorId = axios.interceptors.request.use(async (config) => {

            let user = null;
            try {
                user = await getCurrentUser();
            } catch (error) {//do not remove this line
                user = null;
            }

            if (user && user.userId) {

                let progressDiv = showProgress('body');

                try {
                    const accessToken = await getAWSToken();

                    hideProgress(progressDiv);

                    if (isNotNullAndUndefined(accessToken)) {
                        instance['accessToken'] = accessToken;
                        const bearer = `Bearer ${accessToken}`;
                        config.headers.Authorization = bearer;
                        config.headers[RAFHeaderNames.BusinessUnitUID] = instance['currentBusinessUnitId'];
                        config.headers[RAFHeaderNames.Domain] = getPureSubDomainOrHint();
                    }

                    return config;
                } catch (error) {
                    hideProgress(progressDiv);
                    console.error('Token acquisition failed', error);
                    return Promise.reject(error);
                }

            } else {

                if (!account) {
                    console.error('No active account! Verify a user has been signed in.');
                    return Promise.reject(new Error('No active account! Verify a user has been signed in.'));
                }
                let progressDiv = showProgress('body');

                try {
                    const response = await instance.acquireTokenSilent({
                        scopes: [AzureAdB2C.AcquireTokenScope],
                        account: account,
                    });

                    hideProgress(progressDiv);

                    if (isNotNullAndUndefined(response) && typeof response === 'object') {
                        instance['accessToken'] = response.accessToken;
                        const bearer = `Bearer ${response.accessToken}`;
                        config.headers.Authorization = bearer;
                        config.headers[RAFHeaderNames.BusinessUnitUID] = instance['currentBusinessUnitId'];
                        config.headers[RAFHeaderNames.Domain] = getPureSubDomainOrHint();
                    }

                    return config;
                } catch (error) {
                    hideProgress(progressDiv);
                    console.error('Token acquisition failed', error);
                    return Promise.reject(error);
                }
            }
        },
            (error) => {
                return Promise.reject(error);
            });
    }
    // Add response interceptor
    if (responseInterceptorId === null) {
        responseInterceptorId =
            axios.interceptors.response.use(
                (response) => response,
                (error) => {
                    console.error('Response error:', error);
                    return Promise.reject(error);
                }
            );
    }
    /* eslint-enable no-param-reassign */

    // Optionally clean up the interceptor when component unmounts
    useEffect(() => {
        return () => {
            if (requestInterceptorId !== null) {
                axios.interceptors.request.eject(requestInterceptorId);
                requestInterceptorId = null;
            }
            if (responseInterceptorId !== null) {
                axios.interceptors.response.eject(responseInterceptorId);
                responseInterceptorId = null;
            }
        };
    }, []);

    return (
        <>
            {children}
        </>
    );
};

export default RequestInterceptor;